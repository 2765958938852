import { graphql, StaticQuery } from "gatsby"
import React, { useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import Buttons from "../components/common/button"
import CTA from "../components/common/CTA"
import Frame from "../components/common/frame"
import PopUpForm from "../components/common/PopUpForm"
import Navigation from "../components/navigation"
import Slide from "../components/utility/slide_content"
import "../styles/component/faq.scss"
import "../styles/component/pricing-faq.scss"
import "../styles/pages/roadmap.scss"
import "../styles/pages/super-women.scss"

const SuperWomen = () => {
  const [popupVisibility, setPopupVisibility] = useState(false)
  const togglePopup = () => {
    setPopupVisibility(!popupVisibility)
  }
  const formdata = [
    {
      name: "firstname",
      type: "text",
      label: "Name",
      errorMessage: "Please enter a name",
    },
    {
      name: "jobtitle",
      type: "text",
      label: "Job title",
      errorMessage: "Please enter a job title",
    },
    {
      name: "email",
      type: "email",
      label: "Email address",
      errorMessage: "Please enter a valid email",
    },
    {
      name: "company",
      type: "text",
      label: "Company name",
      errorMessage: "Please enter a company name",
    },
    {
      name: "mobilephone",
      type: "phone",
      label: "Mobile number",
      errorMessage: "Please enter a mobile number",
    },
    {
      name: "partnership_type",
      type: "hidden",
      label: "Partnership",
      value: "Technology Partners",
    },
  ]

  return (
    <>
      <StaticQuery
        query={graphql`
          query superWomen {
            SuperOps {
              pages(where: { title: "super women" }) {
                seo {
                  title
                  description
                  image {
                    url
                  }
                }
                newField {
                  ... on SuperOps_Hero {
                    heroHeading: heading {
                      text
                      html
                    }
                    content {
                      text
                    }
                    images {
                      url
                    }
                    primaryButtonText
                  }
                  ... on SuperOps_Card {
                    cardName
                    heading
                    text
                    subtext
                    image {
                      url
                    }
                  }
                  ... on SuperOps_CtaFeature {
                    ctaBox {
                      type
                      theme
                      customBackgroundColor
                      heading {
                        html
                      }
                      description {
                        html
                      }
                      primaryButtonText
                      primaryButtonLink
                      secondaryButtonText
                      secondaryButtonLink
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <>
            {data.SuperOps.pages.map(page => {
              const { seo, newField } = page
              const hero = newField[0]
              const whyPartner = newField[1]

              return (
                <div className="super-women">
                  <Frame seo={seo} newButton>
                    <header>
                      <Navigation
                        page="About us"
                        dskScroll={450}
                        newButton
                      />
                    </header>

                    <div>
                      <PopUpForm
                        visibility={popupVisibility}
                        togglePopup={val => {
                          setPopupVisibility(val)
                        }}
                        formData={formdata}
                        endpoint={process.env.HUBSPOT_SUPERWOMEN_ENDPOINT}
                        url={process.env.HUBSPOT_SUPERWOMEN_URL}
                        formName="Super women form - Superops"
                        formTitle="Join the SuperWomen Group"
                        successMsg="Submitted successfully!"
                        additionalFormProps={{ IpStackData: true }}
                      />
                    </div>

                    <section
                      className="hero text-center"
                    >
                      <Container className="Layout-container position-relative">
                        <div className="img-wrapper position-absolute left-image d-dsk down-to-up-4">
                          <img
                            src={hero.images[0].url}
                            className="w-100"
                            alt=""
                          />
                        </div>
                        <div className="img-wrapper position-absolute right-image d-dsk down-to-up-4">
                          <img
                            src={hero.images[1].url}
                            className="w-100"
                            alt=""
                          />
                        </div>
                        <h1 className="heading mx-auto fw-bold down-to-up-1">
                          {hero.heroHeading.text}
                        </h1>
                        <div className="p18 down-to-up-2">
                          <p className="description mx-auto">
                            {hero.content.text}
                          </p>
                        </div>
                        <Buttons
                          theme="primary-new mb50 down-to-up-3"
                          arrow
                          onClick={() => togglePopup()}
                          text={hero.primaryButtonText}
                        />{" "}
                      </Container>
                    </section>

                    <section className="why-partner mx-auto down-to-up-5">
                      <Container className="container-box">
                        <h2 className="font-roboto fw-bold mb50 heading text-center">
                          {whyPartner.heading[0]}
                        </h2>
                        <Row className="Layout-container row-gap-24 mx-auto">
                          {whyPartner.text.map((item, id) => {
                            return (
                              <Col lg={4}>
                                <div className="img-wrapper mb16">
                                  <img
                                    src={whyPartner.image[id].url}
                                    className="w-100"
                                    alt="image"
                                  />
                                </div>
                                <p className="p16 fw-bold mb16 m-0">{item}</p>
                                <p className="description mb16 m-0 p14">
                                  {whyPartner.subtext[id]}
                                </p>
                              </Col>
                            )
                          })}
                        </Row>
                      </Container>
                    </section>

                    <section className="mt100">
                      <Slide delay="200">
                        <CTA
                          data={[newField[2].ctaBox]}
                          className="Layout-container"
                          maxHeadingWidth="500px"
                          lgLeft={7}
                          lgRight={4}
                          rightStyles={{ alignItems: "center" }}
                          newButton
                          newDesign
                        />
                      </Slide>
                    </section>
                  </Frame>
                </div>
              )
            })}
          </>
        )}
      />
    </>
  )
}

export default SuperWomen
